import React from "react"
import { css } from "@emotion/core"
import { Link } from 'gatsby'
import LogoWhite from "../images/Tembo_white.svg"
import { withPrefix } from 'gatsby'
import { temboBlue, signupLink, loginLink } from "../utils/constants"
import SubscriptionForm from './subscription-form'


const socialStyles = css`
line-height: 2;
.social-link {
  &:hover,&:focus {
            color: ${temboBlue};
            border-color: white;
            text-decoration: none;
            background: white;
            .social-icon {
              fill: ${temboBlue};
            }
        }
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: ${temboBlue};
        color: white;
        border: 2px solid white;
        width: 39px;
        height: 39px;
        font-size: 1rem;   
        border-radius: 100%;
        &:not(:last-child) {
          margin-right: 5px;
        }

}
.social-icon {
    height: 22px;
    width: 22px;
    display: inline;
    fill: white;
    .svg-use path {
      fill: inherit;
    }
}
`

const RowLink = ({item}) => {
  if (typeof item !== 'object') {
    item = {
      url: `/${item.replace(/\s/g, '-').toLowerCase()}`,
      label: item
    }
  }
  let linkEl
  if (!/^\//.test(item.url)) {
    linkEl = <a href={item.url} className="text-white">{item.label}</a>
  } else {
    linkEl = <Link className="text-white" to={item.url}>{item.label}</Link>
  }
  return (
    <li>{linkEl}</li>
  )
}

const rowOne = [{label: 'Home', url: '/'}, 'About', 'Research', 'Contact']
const rowTwo = [{label: 'Sign Up', url: 'https://beta.temboeducationgroup.com/register' }, {label: 'Log In', url: 'https://beta.temboeducationgroup.com/login'}, 'Privacy Policy', 'Terms of Use']

const socialIcons = [
  { name: "twitter", username: "temboeducation" },
  { name: "instagram", username: "temboeducation" },
  { name: "facebook", username: "temboeducation" },
]

function Footer() {
  return (
    <footer className="bg-primary mt-auto footer">
      <div className="container">
       <SubscriptionForm />
        <div className="row px-4 px-md-0 pt-5 pb-3 justify-content-between">
          <div className="col-xs-12 col-md col-lg-3 mb-4 mb-md-0">
            <Link to="/"><img src={LogoWhite} css={css`width: 200px;`} alt="Tembo logo white" /></Link>
          </div>
          <div className="col-xs-6 col-md col-lg-2">
            <ul className="list-unstyled text-small">
              {rowOne.map(x => 
              
              <RowLink key={x.url || x} item={x} /> )
              }
            </ul>
          </div>
          <div className="col-xs-6 col-md col-lg-2">
            <ul className="list-unstyled text-small">
              {rowTwo.map(x => 
              
              <RowLink key={x.url || x} item={x} /> )
              }
            </ul>
          </div>
          <div className="col-xs-12 col-md col-lg-2">
            <div className="d-block" css={socialStyles}>
              {socialIcons.map(x => (
                <a href={`https://www.${x.name}.com/${x.username}`} className="social-link" key={x.name}>
                 <svg className="social-icon">        
                  <use className="svg-use" xlinkHref={withPrefix(`/brands.svg#${x.name}`)} />
                  </svg>
                </a>
              ))}
            </div>
          </div>
          <div className="col-xs-12 pt-3 mx-3 mx-md-auto text-small text-white"><small>&copy; 2024 Tembo Education Group. All rights reserved.</small></div>
        </div>      
      </div>
    </footer>
  )
}

Footer.propTypes = {

}

export default Footer